
import Vue from 'vue';

/**
 * ----------------------------------------------------------------------------------------
 * Scoped Vue Usage
 * ----------------------------------------------------------------------------------------
 *
 * This is for ad-hoc vue usage. Whenever you want to use any of these components you'll
 * need to add the vue-main class to whatever section of code you want to use these
 * components. These are all lazy loaded so there is arguably no performance impact
 * to having as many components defined here as you'd like.
 *
 * See global-vue.js for vue that runs on every page and is globally available on all
 * pages.
 *
 */

const AdRequest = () => import(/* webpackChunkName: "ad-request" */ '../delivery/requests/adRequest');
const AccountGroups = () => import(/* webpackChunkName: "account-groups" */ '../accountGroups/accountGroups');
const AsgCallout = () => import(/* webpackChunkName: "asg-callout" */ '../js/core/asgCallout');
const AsgSpinner = () => import(/* webpackChunkName: "asg-spinner" */ '../js/core/asgSpinner');
const AsgButton = () => import(/* webpackChunkName: "asg-button" */ '../js/core/asgButton');
const FlatPlan = () => import(/* webpackChunkName: "asg-button" */ '../js/core/asgButton');
const AsgModal = () => import(/* webpackChunkName: "asg-modal" */ '../js/core/asgModal');
const CannedReportManagement = () => import(/* webpackChunkName: "canned-report-management" */'../reporting/reportHandling/cannedReportManagement');
const ContractBonus = () => import(/* webpackChunkName: "contract-bonus" */ '../contract/bonus/contractBonus');
const EditorialDocumentEditor = () => import(/* webpackChunkName: "editorial-document-editor" */'../delivery/content/document/editorialDocumentEditor');
const EditorialsManager = () => import(/* webpackChunkName: "editorials-manager" */'../delivery/content/document/editorialsManager');
const InsertionRenewal = () => import(/* webpackChunkName: "asg-callout" */ '../production/insertions/insertionRenewal');
const ObjectFiles = () => import(/* webpackChunkName: "object-files" */ '../general/fileManager/objectFiles');
const OneTimeModalMessageDisplay = () => import(/* webpackChunkName: "one-time-modal-message-display" */'../js/core/oneTimeModalMessageDisplay');
const PostBoard = () => import(/* webpackChunkName: "post-board" */ '../general/postBoard/postBoard');
const ProjectSelectBox = () => import(/* webpackChunkName: "project-select-box" */ '../general/projects/projectSelectBox');
const QuickbooksInvoice = () => import(/* webpackChunkName: "quickbooks-invoice" */ '../accounting/quickbooks/quickbooksInvoice');
const QuickbooksPayment = () => import(/* webpackChunkName: "quickbooks-payment" */ '../accounting/quickbooks/quickbooksPayment');
const ReportSearch = () => import(/* webpackChunkName: "report-search" */'../reporting/reportSearch');
const SectionSpinner = () => import(/* webpackChunkName: "section-spinner" */ '../js/core/sectionSpinner');
const StudioOrderButton = () => import(/* webpackChunkName: "studio-order-button" */ '../studio/credits/studioOrderButton');

const Test = () => import(/* webpackChunkName: "test" */ '../_dev/vue/test');
const AsgNav = () => import(/* webpackChunkName: "test" */ '../app/asgNav');

// Components that we don't want to have to keep re-registering that we use very often.
import './core/globalVueComponents';

let vueMainContainer = document.querySelector('.vue-main');

if(vueMainContainer !== null) {

    new Vue({
        el: '.vue-main',
        data: function () {
            return {
                //test: 'Hello!'
            };
        },

        components: {
            'flat-plan': FlatPlan,
            'asg-modal': AsgModal,
            'account-groups': AccountGroups,
            'asg-callout': AsgCallout,
            'asg-nav': AsgNav,
            'asg-spinner': AsgSpinner,
            'asg-button': AsgButton,
            'ad-request': AdRequest,
            'canned-report-management': CannedReportManagement,
            'contract-bonus': ContractBonus,
            'editorial-document-editor': EditorialDocumentEditor,
            'editorials-manager': EditorialsManager,
            'insertion-renewal': InsertionRenewal,
            'object-files': ObjectFiles,
            'one-time-modal-message-display': OneTimeModalMessageDisplay,
            'post-board': PostBoard,
            'project-select-box': ProjectSelectBox,
            'report-search': ReportSearch,
            'section-spinner': SectionSpinner,
            'quickbooks-invoice':QuickbooksInvoice,
            'studio-order-button':StudioOrderButton,
            'quickbooks-payment':QuickbooksPayment,
            'test':Test,
        }
    });
}